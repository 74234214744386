.table-list-balances {
  margin-left: 32px;
  &__balance-header {
    display: flex;

    > div {
      min-width: 150px;
      padding: 4px;
      color: var(--color-text-opacity);
    }
  }
  &__balance-body {
    display: flex;

    > div {
      min-width: 150px;
      border: 1px solid #e2e6ea;
      padding: 4px;
      font-weight: 500;
    }
  }
}

.active-card {
  &__info {
    display: flex;
    &__card {
      width: 360px;
    }
    &__data {
      flex: 1;
      padding: 0 30px;
    }
    &__buttons {
      width: 200px;
    }
  }
  &__list {
    &__elem {
      display: flex;
      padding: 10px 0;
      border-bottom: 1px solid #e2e6ea;

      &__title {
        color: var(--color-text-opacity);
        width: 200px;
        font-weight: 500;
      }
      &__value {
      }
    }
  }
}
.input-balance {
  border: 1px solid #e2e6ea;
  border-radius: 6px;
  display: flex;
  height: 42px;
  margin-top: 20px;
  margin-left: 10px;
  align-items: center;
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-primary);
    padding-right: 5px;
    padding-left: 10px;
    margin-right: 10px;
    border-right: 2px solid var(--color-primary);
  }
  &__value {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-text);
  }
}

.cards-block {
  width: max-content;
  &__card:first-child {
    border-top: 1px solid var(--color-text-opacity);
  }
  &__card {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--color-text-opacity);
    &__client {
      min-width: 300px;
      max-width: 300px;
      white-space: normal;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: var(--color-text);
      padding-right: 5px;
      padding-left: 10px;
      margin-right: 10px;
      border-right: 1px solid var(--color-text-opacity);
      border-left: 1px solid var(--color-text-opacity);
      padding-bottom: 5px;
      padding-top: 5px;
      transition: 0.3s;
    }
    &__client:hover {
      opacity: 0.6;
      cursor: pointer;
    }
    &__fuels {
      display: flex;
      align-items: center;
      border-right: 1px solid var(--color-text-opacity);
      max-width: auto;
      flex: 1;
      > div {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: var(--color-text);
        padding-right: 10px;
        padding-left: 0px;
        margin-right: 10px;
        border-right: 2px solid var(--color-text);
      }
      > div:last-child {
        border-right: none;
      }
    }
  }
  &__card:last-child {
    margin-bottom: 0;
  }
}

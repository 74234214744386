@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('./styles/general.scss');

.title-component {
  margin: 26px 0;
}

.container {
  width: calc(100% - 100px);
  margin: auto;
}

.card {
  background: #ff0000;
  border-radius: 12px;
  height: 200px;
  width: 100%;
  box-shadow: 0px 1px 16px rgba(0, 46, 82, 0.05);
  border-radius: 8px;

  &__logo {
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 50px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
      sans-serif;
  }
  &__number {
    padding: 0px 16px;
    padding-top: 10px;
    color: white;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
      'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  &__sum {
    padding: 0px 16px;
    padding-top: 30px;
    color: white;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
      'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 16px;
    span {
      font-size: 12px;
    }
  }
}

.btn-default {
  border-radius: 8px !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  padding: 10px 0 !important;
  text-transform: initial !important;
  width: 100% !important;
}
.btn-blue {
  background: #1688da !important;
}

.btn-red {
  background: #d22323 !important;
}

.btn-green {
  background: #2adba8 !important;
}

.btn-gray {
  background: #edf1f7 !important;
  color: var(--color-text) !important;
}

.MuiTableContainer-root {
  border: none !important;
  table {
    border: none !important;
    tr {
      th {
        color: var(--color-text-opacity);
        font-weight: 600;
        font-size: 14px;
      }
      td {
        color: var(--color-text);
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        border-bottom: 1px solid #e2e6ea;
      }
    }
  }
}
.MuiPaper-root {
  // border-radius: 0 !important;
  // border: none !important;
  // box-shadow: none !important;
}
.mui-input {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  &__label {
    padding-left: 15px;
    padding-bottom: 2px;
  }
  &__field {
    width: 100%;
    max-width: 420px;
    .MuiInputBase-input {
      height: 42px;
      padding: 0;
      padding-left: 15px;
      border-radius: 8px;
      border: none;
    }
    .MuiInputBase-root {
      border-radius: 8px;
      border: 1px solid #e2e6ea;
    }
  }
  &__select {
    .MuiInputBase-input {
      height: 42px !important;
      padding: 0;
      padding-left: 15px;
      border-radius: 8px;
      border: none;
      display: flex;
      align-items: center;
    }
    .MuiPaper-root {
      border: 1px solid black !important;
    }
  }
  &__autocomplete {
    height: 42px !important;
    padding: 0;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    .MuiInputBase-input {
      padding-top: 0 !important;
      height: 24px !important;
      padding: 0 !important;
    }
  }
}

.miu-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  padding-left: 10px;
  .MuiButtonBase-root {
    color: var(--color-text-opacity);
    padding: 0px;
  }
  .MuiSvgIcon-root {
    color: var(--color-text);
  }
  &__checkbox {
  }
  &__label {
    padding-left: 5px;
  }
}
.icons-delete-edit {
  img {
    padding: 0 10px;
    padding-right: 0;
    cursor: pointer;
    transition: 0.3s;
  }
  img:hover {
    opacity: 0.5;
  }
}
.na-filter {
  &__fields {
    display: flex;
    flex-wrap: wrap;
    > div {
      padding-right: 15px;
      width: 100%;
      max-width: 300px;
      min-width: 250px;
    }
  }
}

.react-datepicker-wrapper {
  height: 42px;

  .react-datepicker__input-container {
    display: flex;
    height: 42px;
    input {
      height: 38px;
      border-radius: 4px;
      border: 2px solid #e2e6ea;
      padding-left: 15px;
      max-width: 300px;
      width: 100%;
      font-size: 15px;
    }
  }
}

.button-print {
  height: 42px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  background-color: var(--color-text-opacity);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}
.button-print:hover {
  opacity: 0.7;
}

@import url('./text.scss');
@import 'react-datepicker/dist/react-datepicker.css';

:root {
  --color-primary: #1688da;
  --color-ui-border: #e2e6ea;
  --color-hover: #deebff;
  --color-text-opacity: #97a9b7;
  --color-text: #002e52;
}

.custom-card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 14px 16px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  &__logo {
    img {
      height: 20px;
    }
  }
  &__number {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: var(--color-text);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 22px;
    margin-top: 22px;
  }
  &__block-balance {
    &__fuels {
      display: flex;
      flex-wrap: wrap;
    }
    &__title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: var(--color-text-opacity);
    }
    &__value {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: var(--color-text);
      border-right: 1px solid var(--color-text-opacity);
      padding-right: 5px;
      margin-right: 5px;
      margin-bottom: 5px;
    }
    &__value:last-child {
      border-right: none;
    }
  }
  &__custom-name {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: var(--color-text-opacity);
    margin-top: 17px;
  }
}

.back {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 16px;
  font-size: 14px;
  color: var(--color-text);
  font-weight: 500;
}
.back:hover {
  opacity: 0.7;
}

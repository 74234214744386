.report-components-row {
  display: flex;
  border-top: 1px solid #e2e6ea;
  padding: 12px 4px;
  cursor: pointer;
  transition: 0.3s;
  &__title {
    flex: 1;
    white-space: normal;
  }
  &__body {
    flex: 1.3;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 32px;
    &__header {
      display: flex;
      > div {
        width: 150px;
        padding: 2px 4px;
      }
      > div:first-child {
        width: 300px;
      }
    }
  }
}
.report-components-row:hover {
  opacity: 0.7;
}

.card-fuel {
  display: flex;
  flex-direction: row;

  > div {
    width: 150px;
    border: 1px solid #97a9b7;
    padding: 4px;
  }
  > div:first-child {
    width: 300px;
  }
}
.card-fuel:last-child {
  border: none;
}

.table-list-balances-show-client {
  margin-top: 16px;
  &__balance-header {
    display: flex;

    > div {
      padding: 4px;
      color: var(--color-text-opacity);
    }
  }
  &__balance-body {
    display: flex;

    > div {
      display: flex;
      align-items: center;
      border: 1px solid #e2e6ea;
      padding: 4px;

      font-weight: 500;
    }
  }
}

.home {
  margin-bottom: 30px;
  &__widgets {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 20px;
    &__block {
      background: #1688da;
      box-shadow: 0px 1px 16px rgba(0, 46, 82, 0.05);
      border-radius: 8px;
      background-repeat: no-repeat;
      background-position: right;
      padding: 20px;
      &__value {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: white;
        padding-bottom: 10px;
      }
      &__title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: white;
      }
    }
  }
  &__working-block {
    border: 2px dashed #97a9b7;
    border-radius: 8px;
    height: 150px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-default {
      width: 250px !important;
    }
  }
}

.transactions {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  .btn-default {
    width: 250px !important;
  }
}

.stations {
  margin-bottom: 20px;
  &__toolbar {
    display: flex;
    align-items: center;
    > div:last-child {
      width: 200px;
      margin-left: auto;
    }
  }
}

.station-form {
  width: calc(100% - 340px);
  margin: auto;
  padding: 8px 30px;
  padding-bottom: 24px;

  &__block-title {
  }
  &__block-buttons {
    margin-top: 24px;
    width: 300px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
  &__block-two-column {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    &__left {
      background-color: white;
      box-shadow: 0px 1px 16px rgba(0, 46, 82, 0.05);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      padding: 30px;
    }
    &__right {
      background-color: white;
      box-shadow: 0px 1px 16px rgba(0, 46, 82, 0.05);
      border-radius: 8px;
      padding: 30px;
      &__block-button {
        width: 250px;
        margin-left: auto;
        margin-bottom: 30px;
      }
      &__table {
        &__title {
          display: grid;
          grid-template-columns: 3fr 2fr 1fr;
          padding-bottom: 10px;
          border-bottom: 1px solid var(--color-text-opacity);
          &__field-fio {
            color: var((--color-text-opacity));
          }
          &__field-position {
            color: var((--color-text-opacity));
          }
          &__field-delete {
          }
        }
        &__content {
          .card-user {
            display: grid;
            grid-template-columns: 3fr 1fr;
            align-items: center;
            box-shadow: 0px 1px 16px rgba(0, 46, 82, 0.05);
            padding: 18px;
            border-radius: 8px;
            margin-top: 16px;
            &__fio {
            }

            &__delete {
              margin-left: auto;
              img {
                cursor: pointer;
                transition: 0.3s;
              }
              img:hover {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
}

.clients {
  &__toolbar {
    display: flex;
    align-items: center;
    > div:last-child {
      width: 200px;
      margin-left: auto;
    }
  }
}

.client-form {
  width: calc(100% - 340px);
  margin: auto;
  padding: 8px 30px;
  padding-bottom: 24px;
  &__list-cards {
    background-color: white;
    box-shadow: 0px 1px 16px rgba(0, 46, 82, 0.05);
    border-radius: 8px;
    padding: 1px 30px;
    padding-bottom: 20px;
    &__table {
      &__header {
        display: flex;
        flex-direction: row;
        padding-bottom: 16px;
        border-bottom: 1px solid #e2e6ea;
        padding-bottom: 8px;

        > div {
          color: var((--color-text-opacity));
          font-weight: 500;
          padding-left: 16px;
          width: 250px;
        }
      }
      &__content {
        display: flex;
        flex-direction: row;
        padding-top: 16px;
        padding-bottom: 16px;
        transition: 0.3s;
        // border-radius: 8px;
        border-bottom: 1px solid var(--color-text-opacity);
        > div {
          width: 250px;
          padding-left: 16px;
        }
      }
      &__content:hover {
        cursor: pointer;
        box-shadow: 0px 1px 16px rgb(0 46 82 / 5%);
      }
    }
  }
  &__block-title {
  }
  &__block-buttons {
    margin-top: 24px;
    width: 300px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
  &__block-two-column {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    &__left {
      background-color: white;
      box-shadow: 0px 1px 16px rgba(0, 46, 82, 0.05);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      padding: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
    }
  }
}

.print {
}

.print-content {
  display: none;
}

@media print {
  .print-content {
    display: block !important;
  }
}

.client-show {
  position: relative;
  &__block-body {
    display: flex;
    flex-direction: column;
    &__info {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 220px;
      .card-petrol {
        display: flex;
        padding-top: 5px;
        &__title {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          font-weight: 500;
          color: gray;
          padding-right: 10px;
          min-width: 50px;
        }
        &__value {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          font-weight: 600;
          color: var(--color-text);
          padding-right: 10px;
        }
      }
    }
    &__employees {
      width: 100%;
      .card-user {
        box-shadow: 0px 1px 16px rgb(0 46 82 / 5%);
        padding: 18px;
        border-radius: 8px;
        margin-top: 8px;
        display: flex;
        > div {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          font-weight: 600;
          color: var(--color-text);
          min-width: 400px;
          // flex: 1;
        }
        > div:first-child {
          min-width: 50px;
        }
        > div:last-child {
          min-width: 100px;
        }
      }
    }
  }

  &__tabs {
    .cards-client {
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      &__card {
        margin: 0 10px;
        border-radius: 8px;
        padding: 16px;
        box-shadow: 0px 1px 16px rgb(0 46 82 / 5%);
        min-width: 260px;
        margin-bottom: 16px;
        &__blocked {
          transition: 0.3s;
          opacity: 0.7;
          box-shadow: 0px 1px 16px rgba(0, 46, 82, 0.1);
        }
        &__blocked:hover {
          opacity: 1;
        }
        &__number {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          font-weight: 600;
          color: var(--color-text);
          padding-right: 10px;
          > div {
            font-size: 12px;
            color: var(--color-text-opacity);
            margin-top: -3px;
            font-weight: 400;
          }
          margin-bottom: 5px;
        }
        &__button-edit {
          transition: 0.3s;
          display: flex;
          box-shadow: 0px 1px 16px rgb(0 46 82 / 10%);
          padding: 8px;
          margin-top: 12px;
          border-radius: 4px;
          font-size: 14px;
          align-items: center;
          color: var(--color-text);
          > div:last-child {
            margin-left: auto;
            display: flex;
          }
          cursor: pointer;
        }
        &__button-edit:hover {
          opacity: 0.8;
        }
        &__status {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          font-weight: 600;
          color: var(--color-text);
          padding-right: 10px;
          > div {
            font-size: 12px;
            color: var(--color-text-opacity);
            margin-top: -3px;
            font-weight: 400;
          }
        }
        &__balances {
          margin-top: 16px;

          display: grid;
          grid-template-columns: repeat(2, 1fr);
          &__balance {
            text-align: center;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: var(--color-text);
            padding-right: 5px;
            padding-bottom: 5px;
            padding-top: 5px;
            border: 1px dashed var(--color-text-opacity);
          }
        }
      }
    }
  }
}
